import { useContext } from 'react'
import { FeatureFlagContext, FeatureFlagContextType } from './context/FeatureFlagProvider'

export function useFeatureFlag(): FeatureFlagContextType {
  const context = useContext(FeatureFlagContext)
  if (context === undefined) {
    throw new Error('useFeatureFlag must be used within a FeatureFlagContext')
  }
  return context
}

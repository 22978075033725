import React, { createContext } from 'react'
import { useCookie } from '@utils/Cookies'
import { FeatureFlagsResponse } from '../constants'
import { filterFeatureFlags } from '../utils'

interface FeatureFlagProviderProps {
  children: React.ReactNode
  featureFlags?: FeatureFlagsResponse
}

export interface FeatureFlagContextType {
  flags: {
    [key: string]: string | boolean | number | Array<{ type: string }>
  }
}

export const FeatureFlagContext = createContext<FeatureFlagContextType | undefined>(undefined)

const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = props => {
  const { children, featureFlags } = props

  const monetateId = useCookie('mt.v')
  const filteredFlags = filterFeatureFlags(featureFlags || {}, monetateId || '')

  const value = {
    flags: filteredFlags,
  }
  return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>
}

export default FeatureFlagProvider

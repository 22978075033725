import config from '@configs/index'
import { IPageBaseProps, dispatchCommonComponents, fetchData } from '@foundation/dataFetch'
import { getServerSideStoreInfo } from '@services/ssr'
import { IPage } from '@typesApp/cms'
import { serverSidePropsWrapper } from '@utils/SSR/wrapperGetServerProps'
import { PAGE_TYPES, getHeaders, setHeaders } from '@utils/httpHeadersUtils'
import { GetServerSideProps } from 'next/types'
import { default as AppBase } from '../App'
import HomePage from '../pages_views/Home'
import { getLocalesDomainsConfig } from '@utils/locale'
import nextConfig from '@root/next.config.js'
import { fetchFeatureFlags } from '@foundation/featureFlag'
import FeatureFlagProvider from '@foundation/featureFlag/context/FeatureFlagProvider'
import { getHostnameUrls } from '@utils/getHostnameUrls'
import { generateAlternateUrlMapping, processHomepageAlternateSeoUrls } from '@utils/seo.util'
import * as Sentry from '@sentry/nextjs'

interface IHomeIndexProps extends IPageBaseProps {
  pageData: IPage
  host: string
  seoAlternateUrls: Record<string, string>
}

const HomeIndex: React.FC<IHomeIndexProps> = props => {
  const {
    pageData,
    siteDataRes,
    locale,
    header,
    megaMenu,
    footer,
    preFooter,
    seoAlternateUrls,
    featureFlags,
    ...rest
  } = props

  dispatchCommonComponents({ locale, siteDataRes, header, megaMenu, footer, preFooter, rest })

  return (
    <AppBase>
      <FeatureFlagProvider featureFlags={featureFlags}>
        <HomePage homePageInitial={{ ...pageData, seoAlternateUrls }} />
      </FeatureFlagProvider>
    </AppBase>
  )
}

export const getServerSideProps: GetServerSideProps<IHomeIndexProps | {}> = serverSidePropsWrapper(
  async (stateManager, context) => {
    const { res, req } = context
    const { hostname, protocol } = getHostnameUrls(context.req)
    const domains = nextConfig.i18n.domains
    const { isLocaleDomain } = getLocalesDomainsConfig(context.req, domains)
    const host = req.headers['host'] ?? null

    const sectionLimit = parseInt(config.homepageSectionLimit || '0', 10)

    const { pageData, siteDataRes, locale, header, megaMenu, footer, preFooter } = await fetchData({
      context,
      stateManager,
      sectionLimit,
      pageType: PAGE_TYPES.HOME,
      isLocaleDomain,
    })

    setHeaders(res, getHeaders({ pageType: PAGE_TYPES.HOME, locale }))

    const businessUnitId = siteDataRes?.xStoreCfg?.monetateId
    const featureFlags = await fetchFeatureFlags(businessUnitId || '', host || '')
    const homepageSeoAlternateUrls = processHomepageAlternateSeoUrls()
    const formattedSeoAlternateUrls = generateAlternateUrlMapping(homepageSeoAlternateUrls, protocol, hostname)

    const payload = {
      ...(await getServerSideStoreInfo(siteDataRes, locale)),
      pageData: pageData as IPage,
      seoAlternateUrls: formattedSeoAlternateUrls,
      siteDataRes,
      locale,
      header,
      megaMenu,
      footer,
      preFooter,
      isLocaleDomain,
      host,
      featureFlags: featureFlags,
    }

    return {
      props: {
        ...payload,
      },
    }
  }
)

export default HomeIndex

import CryptoJS from 'crypto-js'
import { FeatureFlagsResponse } from './constants'

const isFeatureEnabledForUser = (flag: { percent: number }, monetateId: string): boolean => {
  // Create a SHA256 hash of the user's monetate ID
  const hash = CryptoJS.SHA256(monetateId).toString(CryptoJS.enc.Hex)
  // Convert the first 8 characters of the hash to an integer
  const userHashInt = parseInt(hash.substring(0, 8), 16)
  const percentage = userHashInt % 100
  // Check if the feature should be enabled for the user
  return percentage < flag.percent
}

/**
 * Filters feature flags for a user based on their monetate ID.
 *
 * @param {FeatureFlagsResponse} flags - The object containing all feature flags returned from server.
 * @param {string} monetateId - The unique identifier for the user.
 * @returns {Object} - An object with the feature flags that are enabled for the user.
 */

export const filterFeatureFlags = (flags: FeatureFlagsResponse, monetateId: string) => {
  const filteredFlags = {}
  for (let key in flags) {
    const isEnabled = isFeatureEnabledForUser(flags[key], monetateId)
    filteredFlags[key] = isEnabled
  }

  return filteredFlags
}
